
import { defineComponent } from "vue";
import {
  primariesNum,
  selectedItemData,
  selectedOrder,
  getOptions,
} from "./edit-selected-order";

export default defineComponent({
  name: "OptionTemplateEdit",
  setup() {
    return {
      selectedOrder,
      itemData: selectedItemData,
      primariesNum,
      getOptions,
    };
  },
  data() {
    return {
      maxSelectableNum: false,
    };
  },
  methods: {
    optionValueChecked(id) {
      return this.itemData.selectedSecondary.includes(id);
    },
    selectedOptionValue({ option_secondary_id }) {
      return this.selectedOrder.order_items.filter(
        ({ item_id }) => item_id == option_secondary_id
      ).length;
    },
    setSelectedSecondary(
      id: string,
      max_sel: number,
      value: number,
      event: any
    ) {
      if (event.target.checked) {
        this.itemData.selectedSecondary.push(value);
      } else {
        this.itemData.selectedSecondary = this.itemData.selectedSecondary.filter(
          (sec) => sec != value
        );
      }
      this.getSecondaryArr(id, max_sel);
    },
    getSecondaryArr(id, max_sel) {
      this.itemData.secondaryArr = [];
      this.itemData.secondaries.map((el) => {
        el.secondary_option_value.map((item) => {
          if (
            this.itemData.selectedSecondary.includes(item.option_secondary_id)
          ) {
            if (
              this.itemData.secondaryArr.findIndex(
                (x) => x.item_id == item.option_secondary_id
              ) === -1
            ) {
              this.itemData.secondaryArr.push({
                sec_id: el.id,
                item_id: item.option_secondary_id,
                quantity:
                  item.qnt <= item.max_count * this.itemData.count
                    ? item.qnt
                    : 1,
                option_name: item.name,
                price: item.price,
              });
            } else {
              this.itemData.secondaryArr[
                this.itemData.secondaryArr.findIndex(
                  (x) => x.item_id == item.option_secondary_id
                )
              ].quantity++;
            }
          }
        });
      });
      if (this.itemData.secondaryArr.length > max_sel) {
        this.maxSelectableNum = true;
      } else {
        this.maxSelectableNum = false;
      }
    },
  },
});
