
import { defineComponent } from "vue";
import CategoryOptionsEdit from "./CategoryOptionsEdit.vue";
import OptionTemplateEdit from "./OptionTemplateEdit.vue";
import {
  primariesNum,
  selectedItemData,
  selectedOrder,
  updateSelectedItemInCart,
} from "./edit-selected-order";

export default defineComponent({
  name: "EditItem",
  components: { CategoryOptionsEdit, OptionTemplateEdit },
  setup() {
    return {
      itemData: selectedItemData,
      primariesNum,
      selectedOrder,
      updateSelectedItemInCart,
    };
  },
  emit: ["edited"],
  data() {
    return {
      maxSelectableNum: false,
      maxSelectableNumCat: false,
      url: localStorage.getItem("imgURL"),
    };
  },
  methods: {
    getSecondaryArrCount() {
      let x: any[] = [];
      this.itemData.secondaries.map((el) => {
        el.secondary_option_value.map((item) => {
          if (
            this.itemData.selectedSecondary.includes(item.option_secondary_id)
          ) {
            if (!(item.max_count > 1 || this.itemData.count > 1)) {
              x.push({
                sec_id: el.id,
                item_id: item.option_secondary_id,
                quantity: 1,
                option_name: item.name,
                price: item.price,
              });
            } else {
              x.push({
                sec_id: el.id,
                item_id: item.option_secondary_id,
                quantity:
                  item.qnt <= item.max_count * this.itemData.count
                    ? item.qnt
                    : 1,
                option_name: item.name,
                price: item.price,
              });
            }
          }
        });
      });
      this.itemData.secondaryArr = x;
    },
    getSecondaryArr(id, max_sel) {
      this.itemData.secondaryArr = [];
      this.itemData.secondaries.map((el) => {
        el.secondary_option_value.map((item) => {
          if (
            this.itemData.selectedSecondary.includes(item.option_secondary_id)
          ) {
            this.itemData.secondaryArr.push({
              sec_id: el.id,
              item_id: item.option_secondary_id,
              quantity:
                item.qnt <= item.max_count * this.itemData.count ? item.qnt : 1,
              option_name: item.name,
              price: item.price,
            });
          }
        });
      });
      if (this.itemData.secondaryArr.length > max_sel) {
        this.maxSelectableNum = true;
      } else {
        this.maxSelectableNum = false;
      }
    },
  },
});
