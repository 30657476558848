
import { defineComponent } from "vue";
import {
  primariesNum,
  selectedItemData,
  selectedOrder,
} from "./edit-selected-order";

export default defineComponent({
  name: "CategoryOptionsEdit",
  setup() {
    return { itemData: selectedItemData, primariesNum, selectedOrder };
  },
  data() {
    return {
      maxSelectableNumCat: false,
    };
  },
  methods: {
    setSelectedCategory(el, event) {
      this.checkOrUnCheck("categories", el, event);
    },
    setSelectedOptions(el, event) {
      this.checkOrUnCheck("catOptions", el, event);
    },
    checkOrUnCheck(
      selected_array: string,
      el: Record<string, any>,
      event: Record<string, any>
    ) {
      // if element not found and the input is checked (i.e. opotion selected)
      // add it to categories
      if (
        this.itemData[selected_array].filter((itm) => itm.name === el.name)
          .length === 0 &&
        event.target.checked
      ) {
        let sizes = ["Small", "Medium", "Large"];
        if (sizes.includes(el.name)) {
          // remove current size
          let excluded = sizes.filter((opt) => el.name !== opt);
          // remove other sizes from catOptions
          this.itemData[selected_array] = this.itemData[selected_array].filter(
            (itm) => !excluded.includes(itm.name)
          );
        }
        // push el to catOptions (e.i. selected_options)
        this.itemData[selected_array].push(el);
      }
      // if input not checked
      // remove element from this.itemData[selected_array]
      if (!event.target.checked) {
        this.itemData[selected_array] = this.itemData[selected_array].filter(
          (itm) => itm.name != el.name
        );
      }
    },
    minusQauntityForValue(value) {
      this.itemData.catOptions = this.itemData.catOptions.map((opt) => {
        if (opt.id == value.id) {
          opt.qnt ? opt.qnt-- : (opt.qnt = 1);
        }
        return opt;
      });
    },
    plusQauntityForValue(value) {
      this.itemData.catOptions = this.itemData.catOptions.map((opt) => {
        if (opt.id == value.id) {
          opt.qnt ? opt.qnt++ : (opt.qnt = 1);
        }
        return opt;
      });
    },
    changeQauntityForValue(value, event) {
      this.itemData.catOptions = this.itemData.catOptions.map((opt) => {
        if (opt.id == value.id) {
          opt.qnt = Number.parseInt(event.target.value);
        }
        return opt;
      });
    },
  },
});
